.container {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 400px;
}

.control {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.control > svg {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

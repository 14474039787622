body {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled,
button:disabled:hover {
  box-shadow: var(--box-shadow, 0 0 0 0 currentColor);
  transform: var(--transform, translate(0, 0));
}
